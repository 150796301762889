import React, { useState, useEffect, useRef } from 'react';
import './../../assets/css/LiveChatbot.css';

const Chatbot = ({ config }) => {
    const [messages, setMessages] = useState([]);
    const [userInput, setUserInput] = useState('');
    const [isRecording, setIsRecording] = useState(false);
    const [audioBlob, setAudioBlob] = useState(null);
    const [langCode, setLangCode] = useState(localStorage.getItem('lang_code') || 'en');
    const [sessionId, setSessionId] = useState(localStorage.getItem('session_code'));
    const [botSpeaking, setBotSpeaking] = useState(false); // Track bot speaking state
    const [showChatbot, setShowChatbot] = useState(false); // To toggle chatbot visibility

    const messagesEndRef = useRef(null);
    const audioContextRef = useRef(null);
    const audioBufferSourceRef = useRef(null);
    const mediaRecorderRef = useRef(null);

    const websitePath = "https://klonit-api-klonit.f6qcwr.easypanel.host/";
    const ASSET_BASE_URL = "https://demos.klonit.net/assets";

    const defaultConfig = {
        baseUrl: ASSET_BASE_URL,
        botName: 'KlonIT',
        avatarImage: `${ASSET_BASE_URL}/img/full_img.png`,
        website_url: 'https://klonit.net/',
        gif_img_path: `${ASSET_BASE_URL}/img/full_img.gif`, // Use .gif for animation
        png_img_path: `${ASSET_BASE_URL}/img/full_img.png`,
        app_voice_type: 'FEMALE',
        website_path: 'Klonit',
    };

    const finalConfig = { ...defaultConfig, ...config };



    useEffect(() => {
        if (!sessionId) {
            const newSessionId = generateRandomString(16);
            setSessionId(newSessionId);
            localStorage.setItem('session_code', newSessionId);
        }



        const savedHistory = JSON.parse(localStorage.getItem(finalConfig.website_path) || "[]");
        setMessages(savedHistory);

        audioContextRef.current = new (window.AudioContext || window.webkitAudioContext)();

        navigator.mediaDevices.getUserMedia({ audio: true })
            .then(stream => {
                mediaRecorderRef.current = new MediaRecorder(stream);
                mediaRecorderRef.current.ondataavailable = e => {
                    setAudioBlob(new Blob([e.data], { type: 'audio/ogg; codecs=opus' }));
                };
                mediaRecorderRef.current.onstop = () => handleAudioStop();
            })
            .catch(err => console.error("Error accessing microphone:", err));

            // Initial bot message
            const initialBotMessage = {
                message: "Hello, I’m a virtual concierge Avatar at your service. Ask me any question or service you like. Ask me any question or support you need.",
                user: "bot",
                chat_id: null,
            };
            setMessages(prevMessages => [...prevMessages, initialBotMessage]);



    }, []);

    useEffect(() => {
        // Scroll to bottom whenever messages update
        scrollToBottom();
    }, [messages]);



    const generateRandomString = (length) => {
        let result = '';
        const characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
        for (let i = 0; i < length; i++) {
            result += characters.charAt(Math.floor(Math.random() * characters.length));
        }
        return result;
    };

    const formatMessage = (message) => {

        message = message.replace(/\*\*(.*?)\*\*/g, '<b>$1</b>');
        message = message.replace(/<thinking>[\s\S]*?<\/thinking>/g, '');
        message = message.replace(/<reflection>[\s\S]*?<\/reflection>/g, '');

        const lines = message.split('\n');
        let inList = false;
        let formattedMessage = '';

        lines.forEach(line => {
            const trimmedLine = line.trim();
            if (trimmedLine.startsWith('- ')) {
                if (!inList) {
                    formattedMessage += '<ul>';
                    inList = true;
                }
                formattedMessage += `<li>${trimmedLine.substring(2)}</li>`;
            } else {
                if (inList) {
                    formattedMessage += '</ul>';
                    inList = false;
                }
                formattedMessage += `<p>${line}</p>`; // Wrap in <p> tag for non-list items
            }
        });

        if (inList) {
            formattedMessage += '</ul>';
        }

        return formattedMessage;
    };

    const saveChatHistory = () => {
        localStorage.setItem(finalConfig.website_path, JSON.stringify(messages));
    };

    const handleAudioStop = () => {
        if (audioBlob) {
            const formData = new FormData();
            const fileName = getDateTimeStamp() + ".wav";
            formData.append('audio', audioBlob, fileName);
            formData.append('currentDateTimeNow', getDateTimeStamp());
            formData.append('website_path', finalConfig.website_path);
            formData.append('voice_type', finalConfig.app_voice_type);
            formData.append('lang_type', langCode);

            addUserMessage(URL.createObjectURL(audioBlob), "audio"); // Display audio message

            fetch(`${websitePath}transcript`, {
                method: 'POST',
                body: formData
            })
                .then(response => response.json())
                .then(data => {
                    if (data.transcript) {
                        addUserMessage(data.transcript);
                        getBotResponse(data.transcript);
                    } else {
                        console.error("Transcription failed");
                        addBotMessage("I didn't understand. Please try again.");
                    }
                })
                .catch(error => {
                    console.error('Transcription error:', error);
                    addBotMessage("There was an error. Please try again.");
                });
        }
    };

    const getBotResponse = (userMessage) => {

        fetch(`${websitePath}get-prompt`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify({
                prompt: userMessage,
                website_path: finalConfig.website_path,
                voice_type: finalConfig.app_voice_type,
                lang_type: langCode,
                session_type: sessionId
            })
        })
        .then(response => response.json())
        .then(data => {
            addBotMessage(data.bot_reply, data.chat_id);
            if (data.ans_voice) {
                playAudioFromBase64(data.ans_voice);
            }

            //Update iframe src
            if (data.website_link) {

            }

        })
        .catch(error => {
            console.error('Error getting bot response:', error);
            addBotMessage("There was an error. Please try again.");
        });
    };

    const handleStartRecording = () => {
        setIsRecording(true);
        mediaRecorderRef.current.start();
    };

    const handleStopRecording = () => {
        setIsRecording(false);
        mediaRecorderRef.current.stop();
    };

    const addUserMessage = (message, messageType="text") => {
        setMessages(prevMessages => [...prevMessages, { message: message, user: 'user', messageType: messageType}]);
        saveChatHistory();

    };

    const addBotMessage = (message, chat_id = null) => {
        if (message) {
          setMessages(prevMessages => [...prevMessages, { message: message, user: 'bot', chat_id: chat_id }]);
          saveChatHistory();
        }
      };
      

    const handleSendMessage = () => {
        if (userInput.trim() !== '') {
            addUserMessage(userInput);
            getBotResponse(userInput);
            setUserInput('');
        }
    };




    const scrollToBottom = () => {
        if (messagesEndRef.current) {
            messagesEndRef.current.scrollIntoView({ behavior: "smooth" });
        }
    };

    const handleLanguageChange = (lang) => {
        setLangCode(lang);
        localStorage.setItem('lang_code', lang);
    };

    const getDateTimeStamp = () => {
        const now = new Date();
        return now.toISOString().slice(0, 19).replace('T', ' ').replace(/[-: .]/g, "");
    };

    const playAudioFromBase64 = (base64String) => {
        const base64EncodedAudio = "data:audio/mp3;base64," + base64String;
        fetch(base64EncodedAudio)
            .then(res => res.arrayBuffer())
            .then(arrayBuffer => audioContextRef.current.decodeAudioData(arrayBuffer))
            .then(audioBuffer => {
                if (audioBufferSourceRef.current) {
                    audioBufferSourceRef.current.stop();
                }
                audioBufferSourceRef.current = audioContextRef.current.createBufferSource();
                audioBufferSourceRef.current.buffer = audioBuffer;
                audioBufferSourceRef.current.connect(audioContextRef.current.destination);
                audioBufferSourceRef.current.start(0);

                setBotSpeaking(true); // Set bot speaking to true when audio starts

                audioBufferSourceRef.current.onended = () => {
                    setBotSpeaking(false); // Set bot speaking to false when audio ends
                };

            })
            .catch(error => console.error("Error playing audio:", error));
    };



    const toggleChatbot = () => {
        setShowChatbot(prevState => !prevState);
    };

    const handleStopSpeaking = () => {
        if (audioBufferSourceRef.current) {
            audioBufferSourceRef.current.stop();
        }
        setBotSpeaking(false);
    };



    return (
        <div>
            <button id="voice_enable_btn" onClick={toggleChatbot}>
                {showChatbot ? 'Disable Voice Interaction' : 'Enable Voice Interaction'}
            </button>

            {showChatbot && (
                <div id="chatbot_panel" className="cloneme_chatbot_panel">
                    <div className="cloneme_card">

                        <div className="cloneme_card-header cloneme_msg_head">
                            {/* ... header content ... */}
                            <div className="cloneme_user_info">
                            <span>KlonIT</span>

                            </div>
                            <div className="cloneme_flag_row">
                                {/* ... flag row content ... */}
                                <ul className="cloneme_action_menu">
                                    <li onClick={() => handleLanguageChange('en')}><span data-lang="en" className="fi fi-us"></span> English</li>
                                    {/* ... other languages ... */}
                                </ul>

                            </div>
                        </div>

                        <div className="cloneme_card-body cloneme_msg_card_body">
                            {messages.map((msg, index) => (
                                <div
                                    key={index}
                                    className={`cloneme_d-flex ${msg.user === 'user' ? 'cloneme_justify-content-end' : 'cloneme_justify-content-start'} cloneme_mb-4`}
                                >
                                    {msg.user === "bot" && (
                                        <div className="cloneme_img_cont_msg">
                                            <img src={finalConfig.baseUrl + "/img/bot.jpg"} alt="Bot" className="cloneme_rounded-circle cloneme_user_img_msg" />
                                        </div>
                                    )}
                                    <div
                                        className={msg.user === 'user' ? 'cloneme_msg_cotainer_send' : 'cloneme_msg_cotainer'}
                                        dangerouslySetInnerHTML={{ __html: formatMessage(msg.message) }}
                                    />

                                    {msg.user === "user" && (
                                        <div className="cloneme_img_cont_msg">
                                            <img src={finalConfig.baseUrl + "/img/image2.png"} alt="user" className="cloneme_rounded-circle cloneme_user_img_msg" />
                                        </div>
                                    )}

                                </div>
                            ))}
                            <div ref={messagesEndRef} />
                        </div>

                        <div id="msg_card_footer" className="cloneme_card-footer">
                            <div className="cloneme_input-group">
                                <input
                                    id="send_type_msg"
                                    type="text"
                                    className="cloneme_form-control cloneme_type_msg"
                                    placeholder="Type your message..."
                                    value={userInput}
                                    onChange={e => setUserInput(e.target.value)}
                                    onKeyPress={e => e.key === 'Enter' && handleSendMessage()}
                                />

                                <button id="send_text_btn" onClick={handleSendMessage}>
                                    <i className="cloneme_fas cloneme_fa-location-arrow" />
                                </button>
                            </div>

                            <div>
                                <button onClick={isRecording ? handleStopRecording : handleStartRecording} disabled={!mediaRecorderRef.current}>
                                    {isRecording ? <i className="cloneme_fas cloneme_fa-stop" /> : <i className="cloneme_fas cloneme_fa-microphone" />}

                                </button>

                            </div>

                        </div>
                    </div>
                    <button onClick={handleStopSpeaking} style={{ display: botSpeaking ? 'block' : 'none' }}>
                        Stop Speaking
                    </button>



                    <div id="chatbot_bottom_girl" className="cloneme_chatbot_bottom_girl">
                        <img
                            id="chatbot_bottom_girl_img"
                            className="chatbot_bottom_girl_img"
                            src={botSpeaking ? finalConfig.gif_img_path : finalConfig.png_img_path}
                            alt="Avatar"
                        />
                    </div>





                    {/* ... Rest of the elements remain similar ... */}
                </div>
            )}



        </div>

    );

};

export default Chatbot;
import React, { useState, useEffect } from "react";
import axios from "axios";
import DataTable from "react-data-table-component";
import backendURL from "../../config";
import EditImproveQuestion from "./EditImproveQuestion";

export default function ListImproveQuestions({ dataRefresh, setDataRefresh }) {
  useEffect(() => {
    document.title = 'List Improve Questions | KlonIT Web Application';
    fetchDocuments();
  }, [dataRefresh]);

  const [documents, setDocuments] = useState([]);
  const [filteredDocuments, setFilteredDocuments] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [selectedItem, setSelectedItem] = useState(null);
  const [searchTerm, setSearchTerm] = useState("");

  // Define columns for react-data-table-component
  const columns = [
    { name: "Questions", selector: (row) => row.questions, sortable: true, maxWidth: '315px' },
    { name: "Answers", selector: (row) => row.answers, sortable: true, hide: "md", maxWidth: '315px' },
    { name: "Improved Questions", selector: (row) => row.question_improvement, sortable: true, hide: "md", maxWidth: '315px' },
    { name: "Is Trained?", selector: (row) => (row.is_trained ? "Yes" : "No"), sortable: true, hide: "md", maxWidth: '315px' },
    { name: "Create Date", selector: (row) => row.created_date, sortable: true, hide: "md", maxWidth: '315px' },
    {
      name: "Action",
      cell: (row) => (
        <div className="form-btn-space">
          <button className="deleteIcon" onClick={() => handleDelete(row.id)}>
            <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"><polyline points="3 6 5 6 21 6"></polyline><path d="M19 6v14a2 2 0 0 1-2 2H7a2 2 0 0 1-2-2V6m3 0V4a2 2 0 0 1 2-2h4a2 2 0 0 1 2 2v2"></path></svg>
          </button>
          <button className="EditIcon" onClick={() => handleEditClick(row)}>
            <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" className="feather feather-edit-2 align-middle me-2"><path d="M17 3a2.828 2.828 0 1 1 4 4L7.5 20.5 2 22l1.5-5.5L17 3z"></path></svg>
          </button>
        </div>
      ),
    },
  ];

  const fetchDocuments = async () => {
    try {
      const token = localStorage.getItem('access_token');
      const headers = { Authorization: `Bearer ${token}` };
      const response = await axios.get(`${backendURL}/get-improve-question`, { headers });
      setDocuments(response.data);
      setFilteredDocuments(response.data);
      setLoading(false);
    } catch (error) {
      if (error.response && error.response.status === 401) {
        console.error("Authentication error:", error.response.data.error);
        setError("Authentication error:", error.response.data.error);
      } else {
        console.error("Error fetching data:", error);
        setError("Error fetching data:", error);
      }
    }
  };

  const handleSearch = (event) => {
    const value = event.target.value;
    setSearchTerm(value);
    const filteredData = documents.filter((document) =>
      document.questions.toLowerCase().includes(value.toLowerCase()) ||
      document.answers.toLowerCase().includes(value.toLowerCase()) ||
      document.question_improvement.toLowerCase().includes(value.toLowerCase())
    );
    setFilteredDocuments(filteredData);
  };

  const handleDelete = async (documentId) => {
    try {
      const token = localStorage.getItem('access_token');
      const headers = { Authorization: `Bearer ${token}` };
      await axios.delete(`${backendURL}/delete-improve-question/${documentId}`, { headers });
      setDocuments(documents.filter(doc => doc.id !== documentId));
      setFilteredDocuments(filteredDocuments.filter(doc => doc.id !== documentId));
      setError("Data has been deleted successfully.");
    } catch (error) {
      console.error("Error deleting data:", error);
      setError("Error deleting data:", error);
    }
  };

  const handleEditClick = (document) => {
    setSelectedItem(document);
  };

  const handleSave = () => {
    setSelectedItem(null);
  };

  const handleCancel = () => {
    setSelectedItem(null); 
  };

  const ExpandedRow = ({ data }) => (
    <div style={{ padding: '10px', lineHeight: '1.5' }}>
      <p><strong>Questions:</strong> {data.questions}</p>
      <p><strong>Answers:</strong> {data.answers}</p>
      <p><strong>Question Improvement:</strong> {data.question_improvement}</p>
    </div>
  );

  return (
    <div>
      {selectedItem && (
        <EditImproveQuestion selectedItem={selectedItem} onSave={handleSave} onCancel={handleCancel} />
      )}
    
      <div className="row">
        <div className="col-12 col-lg-12 col-xxl-12 d-flex">
          <div className="card flex-fill">
            <div className="card-header">
              <div className="row">
                <div className="col-lg-4">

                </div>
                <div className="col-lg-4">
                  
                </div>
                <div className="col-lg-4">
                  <input
                    type="text"
                    placeholder="Search..."
                    value={searchTerm}
                    onChange={handleSearch}
                    style={{ marginBottom: "10px", padding: "5px", width: "100%" }}
                  />
                </div>
              </div>
              
            </div>
            <div className="card-body">
              {loading ? (
                <p>Loading...</p>
              ) : filteredDocuments.length === 0 ? (
                <p>No data available</p>
              ) : (
                <DataTable
                  columns={columns}
                  data={filteredDocuments}
                  pagination
                  highlightOnHover
                  pointerOnHover
                  responsive
                  striped
                  expandableRows
                  expandableRowsComponent={({ data }) => <ExpandedRow data={data} />}
                  expandOnRowClicked
                />
              )}
              <br/>
              <div id="getError" className={`alert alert-warning ${error ? "" : "d-none"}`}>{error}</div>
              <br/>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

import React, { useState, useEffect } from "react";
import axios from "axios";
import ReactModal from "react-modal";
import DataTable from "react-data-table-component";
import backendURL from "../../config";

ReactModal.setAppElement("#root");

export default function ListPronoun() {
  useEffect(() => {
    document.title = "Pronoun Lists | KlonIT Web Application";
    fetchDocuments();
  }, []);

  const [documents, setDocuments] = useState([]);
  const [loading, setLoading] = useState(true);
  const [fetching, setFetching] = useState(false);
  const [fetchingText, setFetchingText] = useState("");
  const [fetchingIcon, setFetchingIcon] = useState("");
  const [error, setError] = useState(null);
  const [searchText, setSearchText] = useState("");
  const [filteredDocuments, setFilteredDocuments] = useState([]);

  // Function to fetch documents from the API
  const fetchDocuments = async () => {
    try {
      const token = localStorage.getItem("access_token");
      const headers = {
        Authorization: `Bearer ${token}`,
      };

      const response = await axios.get(`${backendURL}/get-pronoun`, { headers });
      setDocuments(response.data);
      setFilteredDocuments(response.data);
      setLoading(false);
    } catch (error) {
      if (error.response && error.response.status === 401) {
        console.error("Authentication error:", error.response.data.error);
        setError("Authentication error:", error.response.data.error);
      } else {
        console.error("Error fetching Pronoun:", error);
        setError("Error fetching Pronoun:", error);
      }
    }
  };

  const handleDelete = async (documentId) => {
    try {
      const token = localStorage.getItem("access_token");
      const headers = {
        Authorization: `Bearer ${token}`,
      };

      await axios.delete(`${backendURL}/delete-pronoun/${documentId}`, { headers });
      setDocuments(documents.filter((doc) => doc.id !== documentId));
      setFilteredDocuments(filteredDocuments.filter((doc) => doc.id !== documentId));
      setError("Pronoun has been deleted successfully.");
    } catch (error) {
      console.error("Error deleting Pronoun:", error);
      setError("Error deleting Pronoun:", error);
    }
  };

  const handleSearch = (event) => {
    const value = event.target.value;
    setSearchText(value);

    const filteredData = documents.filter((doc) =>
      doc.pronoun.toLowerCase().includes(value.toLowerCase())
    );
    setFilteredDocuments(filteredData);
  };

  const columns = [
    {
      name: "Pronouns",
      selector: (row) => row.pronoun,
      sortable: true,
    },
    {
      name: "Replace Pronoun",
      selector: (row) => row.replace_noun,
      sortable: true,
    },
    {
      name: "Create Date",
      selector: (row) => row.created_date,
      sortable: true,
    },
    {
      name: "Action",
      cell: (row) => (
        <button className="deleteIcon" onClick={() => handleDelete(row.id)}>
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="24"
            height="24"
            viewBox="0 0 24 24"
            fill="none"
            stroke="currentColor"
            strokeWidth="2"
            strokeLinecap="round"
            strokeLinejoin="round"
          >
            <polyline points="3 6 5 6 21 6"></polyline>
            <path d="M19 6v14a2 2 0 0 1-2 2H7a2 2 0 0 1-2-2V6m3 0V4a2 2 0 0 1 2-2h4a2 2 0 0 1 2 2v2"></path>
          </svg>
        </button>
      ),
    },
  ];

  return (
    <div className="row">
      <div className="col-12 col-lg-12 col-xxl-12 d-flex">
        <div className="card flex-fill">
          <div className="card-header">
          <div className="row">
                <div className="col-lg-4">

                </div>
                <div className="col-lg-4">
                  
                </div>
                <div className="col-lg-4">
                  <input
                    type="text"
                    placeholder="Search.."
                    value={searchText}
                    onChange={handleSearch}
                    style={{ marginBottom: "10px", padding: "5px", width: "100%" }}
                  />
                </div>
              </div>

            
          </div>
          <div className="card-body">
            {loading ? (
              <p>Loading...</p>
            ) : filteredDocuments.length === 0 ? (
              <p>No data available</p>
            ) : (
              <DataTable
                columns={columns}
                data={filteredDocuments}
                pagination
                highlightOnHover
                expandableRows
                expandOnRowClicked
                expandableRowsComponent={({ data }) => (
                  <div style={{ padding: "10px" }}>
                    <p><strong>Pronoun:</strong> {data.pronoun}</p>
                    <p><strong>Replace Pronoun:</strong> {data.replace_noun}</p>
                    <p><strong>Create Date:</strong> {data.created_date}</p>
                  </div>
                )}
              />
            )}
            <br />
            <div
              id="getError"
              className={`alert alert-warning ${error ? "" : "d-none"}`}
            >
              {error}
            </div>
            <br />
          </div>
        </div>
      </div>

      <ReactModal
        isOpen={fetching}
        onRequestClose={() => setFetching(false)}
        contentLabel="Fetching Data"
        className="Modal"
        overlayClassName="Overlay"
      >
        <div className="popup-content">
          <i className={fetchingIcon}></i>
          <p>{fetchingText}</p>
        </div>
      </ReactModal>
    </div>
  );
}

import React, { useState, useEffect } from "react";
import axios from "axios";
import DataTable from "react-data-table-component";
import backendURL from "../../config";

export default function ListAudioLanguage(reload) {
  useEffect(() => {
    document.title = 'Audio & Language Settings | KlonIT Web Application';
    fetchDocuments();
  }, [reload]);

  const [documents, setDocuments] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  // Function to fetch documents from the API
  const fetchDocuments = async () => {
    try {
      const token = localStorage.getItem("access_token");
      const headers = {
        Authorization: `Bearer ${token}`,
      };

      const response = await axios.get(`${backendURL}/get-language-audio`, { headers });
      setDocuments(response.data);
      setLoading(false);
    } catch (error) {
      if (error.response && error.response.status === 401) {
        console.error("Authentication error:", error.response.data.error);
        setError("Authentication error:", error.response.data.error);
      } else {
        console.error("Error fetching data:", error);
        setError("Error fetching data:", error.message);
      }
      setLoading(false);
    }
  };

  const handleDelete = async (documentId) => {
    try {
      const token = localStorage.getItem("access_token");
      const headers = {
        Authorization: `Bearer ${token}`,
      };

      await axios.delete(`${backendURL}/delete-language-audio/${documentId}`, { headers });
      setDocuments(documents.filter((doc) => doc.id !== documentId));
      setError("Data has been deleted successfully.");
    } catch (error) {
      console.error("Error deleting data:", error);
      setError("Error deleting data:", error.message);
    }
  };

  const columns = [
    {
      name: "Language",
      selector: (row) => row.website_lang,
      sortable: true,
    },
    {
      name: "Gender",
      selector: (row) => row.website_gender,
      sortable: true,
    },
    {
      name: "Voice",
      selector: (row) => row.website_voice,
      sortable: true,
    },
    {
      name: "Default Language",
      selector: (row) => (row.default_language ? "Yes" : "No"),
      sortable: true,
    },
    {
      name: "Create Date",
      selector: (row) => row.created_date,
      sortable: true,
    },
    {
      name: "Action",
      cell: (row) => (
        <button className="deleteIcon" onClick={() => handleDelete(row.id)}>
          <i className="fa fa-trash-o fa-2x"></i>
        </button>
      ),
    },
  ];

  return (
    <div className="row">
      <div className="col-12 col-lg-12 col-xxl-12 d-flex">
        <div className="card flex-fill">
          <div className="card-header"></div>
          <div className="card-body">
            {loading ? (
              <p>Loading...</p>
            ) : documents.length === 0 ? (
              <p>No data available</p>
            ) : (
              <DataTable
                columns={columns}
                data={documents}
                pagination
                highlightOnHover
                pointerOnHover
              />
            )}
            <br />
            <div id="getError" className={`alert alert-warning ${error ? "" : "d-none"}`}>
              {error}
            </div>
            <br />
          </div>
        </div>
      </div>
    </div>
  );
}

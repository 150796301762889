import React, { useState, useEffect } from "react";
import axios from "axios";
import DataTable from "react-data-table-component";
import Modal from "react-modal";
import EditDocuments from "./EditDocuments";
import backendURL from "../../config";
import RetrainButton from "../Chatbot/RetrainButton"

Modal.setAppElement("#root");

export default function ListDocuments({ refresh }) {
  const [documents, setDocuments] = useState([]);
  const [filteredDocuments, setFilteredDocuments] = useState([]); // State for filtered documents
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [currentDocument, setCurrentDocument] = useState(null);
  const [triggerFetch, setTriggerFetch] = useState(false);
  const [searchText, setSearchText] = useState(""); // State for search input

  useEffect(() => {
    document.title = "Uploaded Files | KlonIT Web Application";
    fetchDocuments();
  }, [refresh, triggerFetch]);

  useEffect(() => {
    // Filter documents based on search text
    const filtered = documents.filter((doc) =>
      Object.values(doc)
        .join(" ")
        .toLowerCase()
        .includes(searchText.toLowerCase())
    );
    setFilteredDocuments(filtered);
  }, [searchText, documents]);

  const fetchDocuments = async () => {
    try {
      const token = localStorage.getItem("access_token");
      const headers = {
        Authorization: `Bearer ${token}`,
      };

      const response = await axios.get(`${backendURL}/get-private-data`, { headers });
      setDocuments(response.data);
      setFilteredDocuments(response.data); // Initialize filtered documents
      setLoading(false);
    } catch (error) {
      if (error.response && error.response.status === 401) {
        console.error("Authentication error:", error.response.data.error);
        setError("Authentication error:", error.response.data.error);
      } else {
        console.error("Error fetching documents:", error);
        setError("Error fetching documents:", error);
      }
    }
  };

  const handleDelete = async (documentId) => {
    try {
      const token = localStorage.getItem("access_token");
      const headers = {
        Authorization: `Bearer ${token}`,
      };

      await axios.delete(`${backendURL}/delete-private-data/${documentId}`, { headers });
      setDocuments(documents.filter((doc) => doc.id !== documentId));
      setError("Document has been deleted successfully.");
    } catch (error) {
      console.error("Error deleting document:", error);
      setError("Error deleting document:", error);
    }
  };

  const handleEdit = (document) => {
    setCurrentDocument(document);
    setIsModalOpen(true);
  };

  const closeModal = () => {
    setIsModalOpen(false);
    setCurrentDocument(null);
    setTriggerFetch(!triggerFetch);
  };

  const columns = [
    { name: "File Name", selector: (row) => row.filename, sortable: true },
    { name: "Page Content", selector: (row) => row.page_content, sortable: true, maxWidth: "315px" },
    { name: "Summary", selector: (row) => row.summary, sortable: true, maxWidth: "315px" },
    { name: "Is Scrape?", selector: (row) => (row.is_scrape ? "Yes" : "No"), sortable: true },
    { name: "Is Trained?", selector: (row) => (row.is_trained ? "Yes" : "No"), sortable: true },
    { name: "Upload Date", selector: (row) => row.upload_date, sortable: true },
    {
      name: "Action",
      cell: (row) => (
        <>
          <button className="deleteIcon" onClick={() => handleDelete(row.id)}>
            <i className="fa fa-trash-o fa-2x"></i>
          </button>
          <button className="EditIcon" onClick={() => handleEdit(row)}>
            <i className="fa fa-pencil fa-2x"></i>
          </button>
        </>
      ),
    },
  ];

  const ExpandedComponent = ({ data }) => (
    <div>
      <p><strong>File Name:</strong> {data.filename}</p>
      <p><strong>Page Content:</strong> {data.page_content}</p>
      <p><strong>Summary:</strong> {data.summary}</p>
    </div>
  );

  const EditModalStyle = {
    content: {
      width: "50%",
      height: "50%",
      margin: "auto",
      padding: "20px",
      borderRadius: "10px",
      boxShadow: "0 4px 6px rgba(0, 0, 0, 0.1)",
    },
    overlay: {
      backgroundColor: "rgba(0, 0, 0, 0.5)",
    },
  };

  return (
    <div className="row">
      <div className="col-12 col-lg-12 col-xxl-12 d-flex">
        <div className="card flex-fill">
        <div className="card-header">
          
              

              <div className="row">
                <div className="col-lg-4">

                </div>
                <div className="col-lg-4">
                  <div className="mt-2">
                    <RetrainButton/>
                  </div>                                    
                </div>
                <div className="col-lg-4">
                  <input
                    type="text"
                    placeholder="Search documents..."
                    className="form-control mb-3"
                    value={searchText}
                    onChange={(e) => setSearchText(e.target.value)}
                  />
                </div>
              </div>
              
            </div>
          <div className="card-body">            
            {loading ? (
              <p>Loading...</p>
            ) : filteredDocuments.length === 0 ? (
              <p>No data available</p>
            ) : (
              <DataTable
                columns={columns}
                data={filteredDocuments}
                pagination
                responsive
                highlightOnHover
                pointerOnHover
                expandableRows
                expandableRowsComponent={({ data }) => <ExpandedComponent data={data} />}
                expandOnRowClicked
              />
            )}
            <br />
            <div id="getError" className={`alert alert-warning ${error ? "" : "d-none"}`}>
              {error}
            </div>
            <br />
          </div>
        </div>
      </div>

      <Modal isOpen={isModalOpen} onRequestClose={closeModal} contentLabel="Edit Document" style={EditModalStyle}>
        <EditDocuments document={currentDocument} onClose={closeModal} />
      </Modal>
    </div>
  );
}

import React, { useState, useEffect } from "react";
import axios from "axios";
import backendURL from "../../config";

export default function RetrainButton() {
	useEffect(() => {
		document.title = 'Retrain Button | KlonIT Web Application';
	}, []);

	const [error, setError] = useState(null);
	const [loading, setLoading] = useState(false);
	const [buttonText, setButtonText] = useState("Retrain Chatbot");

	const handleRetrainBtnClick = async () => {
		setLoading(true);
		setButtonText("Training...");

		try {
			const token = localStorage.getItem('access_token');
			const headers = {
				Authorization: `Bearer ${token}`
			};

			// Send request to retrain chatbot
			const response = await axios.get(`${backendURL}/retrain-chatbot`, { headers });

			setButtonText("Chatbot Trained");
			setError("You have successfully retrained the chatbot.");
		} catch (error) {
			console.error("Error in retraining the chatbot:", error);
			setButtonText("Retrain Chatbot");
			setError("Error in retraining the chatbot.");
		} finally {
			setLoading(false);
		}
	};
	

	return (
		<div>
            <div className="row mb-2 mb-xl-3">
                <div className="col-auto ms-auto text-end mt-n1">
                    <button 
                        className="btn btn-primary mx-2" 
                        onClick={handleRetrainBtnClick}
                        disabled={loading}
                    >
                    {loading ? <> <i className="fa fa-spinner fa-spin"></i> Training </> : buttonText}
                    </button>                   

                </div>
                <div id="getError" className={`alert alert-warning mt-2 ${error ? "" : "d-none"}`}>{error}</div>
            </div>
			
		</div>
	);
}

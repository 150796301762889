import React, { useEffect, useState } from "react";
import axios from "axios";
import backendURL from "../../config";

export default function Prompt() {
  const [message, setMessage] = useState("");
  const [prompt, setPrompt] = useState("");
  const [greeting, setGreeting] = useState(""); // State for greeting text

  // Get token from localStorage
  const token = localStorage.getItem('access_token');
  const headers = { Authorization: `Bearer ${token}` };

  // Fetch the LLM settings (prompt and greeting) from the API on component mount
  useEffect(() => {
    const fetchLLMSettings = async () => {
      try {
        const response = await axios.get(`${backendURL}/llm-settings`, { headers });
        if (response.data) {
          setPrompt(response.data.prompt_text || ""); // Set prompt_text, default to empty string if not found
          setGreeting(response.data.greeting_text || ""); // Set greeting_text, default to empty string if not found
        }
      } catch (error) {
        setMessage("Error fetching LLM settings");
      }
    };
    fetchLLMSettings();
  }, []);

  const handleSave = async () => {
    try {
      const response = await axios.post(
        `${backendURL}/llm-settings`,
        { 
          prompt_text: prompt,
          greeting_text: greeting,
          // Add other fields (greeting_base64) here if needed
        },
        { 
          headers: { 
            Authorization: `Bearer ${token}`,
            'Content-Type': 'application/json'
          } 
        }
      );
      if (response.status === 200 || response.status === 201) {
        setMessage("LLM settings saved successfully!");
      } else {
        setMessage("Error saving LLM settings");
      }
    } catch (error) {
      setMessage("Error saving LLM settings");
    }
  }; 

  return (
    <div>
      <main className="content page-website-configurations">
        <div className="container-fluid p-0">
          <div className="row">
            <div className="col-12 col-lg-12">
              <div className="card">
                <div className="card-body">
                  <div className="row">
                    <div className="col-8 col-lg-8">
                      <h5 className="card-title mb-1">
                        Prompt:
                      </h5>
                      <div className="">
                        <textarea
                          id="prompting"
                          name="prompting"
                          className="form-control"
                          value={prompt}
                          onChange={(e) => setPrompt(e.target.value)}
                          style={{ height: "200px", width: "100%" }}
                        />
                      </div>
                    </div>
                  </div>
                  <div className="row mt-3"> {/* Add some margin-top */}
                    <div className="col-8 col-lg-8">
                      <h5 className="card-title mb-1">
                        Greeting Text:
                      </h5>
                      <div className="">
                        <textarea
                          id="greeting"
                          name="greeting"
                          className="form-control"
                          value={greeting}
                          onChange={(e) => setGreeting(e.target.value)}
                          style={{ height: "200px", width: "100%" }}
                        />
                      </div>
                    </div>
                  </div>
                  <div className="row mt-3">
                    <div className="col-12 col-lg-12">
                      <button className="btn btn-primary" onClick={handleSave}> Save </button>
                    </div>
                  </div>
                  <div className="row mt-3">
                    <div className="col-12 col-lg-12">
                      <div id="getError" className={`alert alert-info ${message ? "" : "d-none"}`}>{message}</div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </main>
    </div>
  );
}
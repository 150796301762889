import React, { useState, useEffect } from "react";
import axios from "axios";
import backendURL from "../config";
import { saveAs } from 'file-saver';
import ChatbotScripts from './Chatbot/ChatbotScripts';
import RetrainButton from "./Chatbot/RetrainButton"

export default function RetrainChatbot() {
	useEffect(() => {
		document.title = 'Retrain Chatbot | KlonIT Web Application';
		fetchDocuments();
	}, []);

	const [error, setError] = useState(null);
	const [totalPageCharactersPrivateDocs, setTotalPageCharactersPrivateDocs] = useState(0);
	const [totalPageWordsPrivateDocs, setTotalPageWordsPrivateDocs] = useState(0);
	const [totalTokenPrivateDocs, setTotalTokensPrivateDocs] = useState(0);
	const [totalPrivateDocs, setTotalPrivateDocs] = useState(0);
	const [totalPageCharactersWebsiteLinks, setTotalPageCharactersWebsiteLinks] = useState(0);
	const [totalPageWordsWebsiteLinks, setTotalPageWordsWebsiteLinks] = useState(0);
	const [totalTokensWebsiteLinks, setTotalTokensWebsiteLinks] = useState(0);
	const [totalWebsiteLinks, setTotalWebsiteLinks] = useState(0);
	const [totalSitemap, setTotalSitemap] = useState(0);
	const [totalPlus, setTotalPlus] = useState(0);
	const [loading, setLoading] = useState(false);
	const [buttonText, setButtonText] = useState("Retrain Chatbot");
	const [scriptFilename, setScriptFilename] = useState("myScript.js");

	const handleRetrainBtnClick = async () => {
		setLoading(true);
		setButtonText("Training...");

		try {
			const token = localStorage.getItem('access_token');
			const headers = {
				Authorization: `Bearer ${token}`
			};

			// Send request to retrain chatbot
			const response = await axios.get(`${backendURL}/retrain-chatbot`, { headers });

			setButtonText("Chatbot Trained");
			setError("You have successfully retrained the chatbot.");
		} catch (error) {
			console.error("Error in retraining the chatbot:", error);
			setButtonText("Retrain Chatbot");
			setError("Error in retraining the chatbot.");
		} finally {
			setLoading(false);
		}
	};

	// Function to fetch documents from the API
	const fetchDocuments = async () => {
		try {
			const token = localStorage.getItem('access_token'); 
			const headers = {
				Authorization: `Bearer ${token}`
			};

			const response = await axios.get(`${backendURL}/get-retrain-summary`, { headers });
			
			// Extract values and set state
			setTotalPageCharactersPrivateDocs(response.data.private_documents.total_page_characters);
			setTotalPageWordsPrivateDocs(response.data.private_documents.total_page_words);
			setTotalTokensPrivateDocs(response.data.private_documents.total_tokens);
			setTotalPrivateDocs(response.data.private_documents.total_documents);

			setTotalPageCharactersWebsiteLinks(response.data.website_links.total_page_characters);
			setTotalPageWordsWebsiteLinks(response.data.website_links.total_page_words);
			setTotalTokensWebsiteLinks(response.data.website_links.total_tokens);
			setTotalWebsiteLinks(response.data.website_links.total_links);

			setTotalPlus(response.data.private_documents.total_page_characters + response.data.website_links.total_page_characters);

			setTotalSitemap(response.data.site_maps.total_sitemaps);


		} catch (error) {
			if (error.response && error.response.status === 401) {
				console.error("Authentication error:", error.response.data.error);
				setError("Authentication error:", error.response.data.error);
			} else {
				console.error("Error fetching documents:", error);
				setError("Error fetching documents:", error);
			}
		}
	};
	

	return (
		<div>
			<main className="content">
				<div className="container-fluid p-0">

					<RetrainButton/>

					<div className="row mb-2 mb-xl-3">
						<div className="col-auto d-none d-sm-block">
							<h3><strong>Retrain</strong> Chatbot</h3>
						</div>
					</div>
					<div className="row">
						<div className="col-sm-6 col-xl-3">
							<div className="card card-2">
								<div className="card-body">
									<div className="row">
										<div className="col mt-0">
											<h5 className="card-title">Numbers of file</h5>
										</div>

										<div className="col-auto">
											<div className="stat text-primary">
												<i className="fa fa-file-o"></i>
											</div>
										</div>
									</div>
									<h1 className="mt-1 mb-3">{totalPrivateDocs}</h1>
									<div className="mb-0">
										<span className="text-muted"></span>
										<span className="badge badge-success-light"> {totalPageCharactersPrivateDocs} </span>
										<span className="text-muted"> characters, </span>
										<span className="badge badge-success-light"> {totalPageWordsPrivateDocs} </span>
										<span className="text-muted"> words and </span>
										<span className="badge badge-success-light"> {totalTokenPrivateDocs} </span>
										<span className="text-muted"> tokens. </span>
									</div>
								</div>
							</div>
						</div>
						<div className="col-sm-6 col-xl-3">
							<div className="card card-2">
								<div className="card-body">
									<div className="row">
										<div className="col mt-0">
											<h5 className="card-title">No. of website links</h5>
										</div>

										<div className="col-auto">
											<div className="stat text-primary">
												<i className="fa fa-link"></i>
											</div>
										</div>
									</div>
									<h1 className="mt-1 mb-3"> {totalWebsiteLinks}</h1>
									<div className="mb-0">
										<span className="text-muted"> </span>
										<span className="badge badge-danger-light">{totalPageCharactersWebsiteLinks} </span>
										<span className="text-muted">characters, </span>
										<span className="badge badge-danger-light">{totalPageWordsWebsiteLinks} </span>
										<span className="text-muted">words and </span>
										<span className="badge badge-danger-light">{totalTokensWebsiteLinks} </span>
										<span className="text-muted">tokens.</span>
									</div>
								</div>
							</div>
						</div>
						<div className="col-sm-6 col-xl-3">
							<div className="card card-2">
								<div className="card-body">
									<div className="row">
										<div className="col mt-0">
											<h5 className="card-title">Number of sitemaps </h5>
										</div>

										<div className="col-auto">
											<div className="stat text-primary">
												<i className="fa fa-sitemap"></i>
											</div>
										</div>
									</div>
									<h1 className="mt-1 mb-3"> {totalSitemap} </h1>
									<div className="mb-0">
										<span className="text-muted">to be found.</span>
									</div>
								</div>
							</div>
						</div>
						<div className="col-sm-6 col-xl-3">
							<div className="card card-2">
								<div className="card-body">
									<div className="row">
										<div className="col mt-0">
											<h5 className="card-title">Total tokens </h5>
										</div>

										<div className="col-auto">
											<div className="stat text-primary">
												<i className="fa fa-font"></i>
											</div>
										</div>
									</div>
									<h1 className="mt-1 mb-3">{totalTokenPrivateDocs + totalTokensWebsiteLinks} </h1>
									<div className="mb-0">
										<span className="text-muted">Total characters {totalPlus} to be found.</span>
									</div>
								</div>
							</div>
						</div>
					</div>				

					<div id="getError" className={`alert alert-warning ${error ? "" : "d-none"}`}>{error}</div> {/* Display error message */}

					{/* New Script Section */}
					<div className="row mt-4">
						<div className="col-md-6">
							<ChatbotScripts />
						</div>
						<div className="col-md-6">
							<h4>Chatbot Interface</h4>
							<div className="card">
								<div className="card-body">
									<div className="chatbot-container">
									{/* Mock chatbot UI */}
									<div className="chatbot-messages">
										<div className="message">Hello! How can I assist you?</div>
									</div>
									<div className="chatbot-input">
										<input type="text" className="form-control" placeholder="Type a message..." />
										<button className="btn btn-primary mt-2">
											Send
										</button>
									</div>
								</div>
								</div>
							</div>
							
						</div>
					</div>


				</div>
			</main>
		</div>
	);
}

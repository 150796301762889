import React, { useEffect, useState } from "react";
import axios from "axios";
import Prompt from "./Prompt";

export default function ChatbotSettings() {


  return (
    <div>
      <main className="content page-website-configurations">
        <div className="container-fluid p-0">
          <div className="mb-3">
            <h1 className="h3 d-inline align-middle"><strong>Chatbot</strong> Settings</h1>
          </div>
          <Prompt/>
        </div>
      </main>
    </div>
  );
}

import React, { useState, useEffect } from "react";
import axios from "axios";
import backendURL from "../../config";
import ListPronoun from './ListPronoun';

export default function AddPronoun() {

  useEffect(() => {
    document.title = 'Add Pronoun | KlonIT Web Application';
  }, []);

  const [pronounValue, setPronounValue] = useState("");
  const [pronoun, setPronoun] = useState([]);
  const [inputReplaceNoun, setInputReplaceNoun] = useState("");
  const [error, setError] = useState(null);


  const handleAddWebBtnClick = async () => {

    try {
      const token = localStorage.getItem('access_token');
      const headers = {
        Authorization: `Bearer ${token}`
      };

      // Send request to add Pronoun
      const response = await axios.post(`${backendURL}/add-pronoun`, { pronoun: pronounValue, replace_noun: inputReplaceNoun }, { headers });

      // Update pronoun state with the new Pronoun
      setPronoun([...pronoun, pronounValue, inputReplaceNoun]);

      setPronounValue("");
      setInputReplaceNoun("");
      setError("Pronoun has been added successfully.");
    } catch (error) {
      console.error("Error adding Pronoun:", error);
      setError("Error adding Pronoun:", error);
    }

  };


  return (
    <div>
      <main className="content">
        <div className="container-fluid p-0">
          <div className="mb-3">
            <h1 className="h3 d-inline align-middle"> <strong>Add Pronoun</strong> </h1>
          </div>
          <div className="row">
            <div className="col-12 col-lg-12">
              <div className="card">
                <div className="card-body">
                  <h5 className="card-title mb-1">
                    Add your pronoun.
                  </h5>
                  <div className="row">
                    <div className="col-4 col-lg-4">
                      <input
                        type="text"
                        className="form-control"
                        placeholder="Add pronoun"
                        value={pronounValue}
                        onChange={(e) => setPronounValue(e.target.value)}
                      />
                    </div>
                    <div className="col-4 col-lg-4">
                      <input
                        type="text"
                        className="form-control"
                        placeholder="Add pronoun replace"
                        value={inputReplaceNoun}
                        onChange={(e) => setInputReplaceNoun(e.target.value)}
                      />
                    </div>
                  </div>
                  <br/>
                  <div className="row">
                    <div className="col-4 col-lg-4">
                      <button
                        className="btn btn-primary"
                        onClick={handleAddWebBtnClick}
                      >
                        Add pronoun
                      </button>

                      <br />
                      <br />
                      <div id="getError" className={`alert alert-warning ${error ? "" : "d-none"}`}>{error}</div> {/* Display error message */}


                    </div>

                  </div>
                </div>
              </div>
            </div>
          </div>


          <ListPronoun key={pronoun.length} />
        </div>
      </main>
    </div>
  );
}

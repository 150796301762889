import React from "react";
import { Link,NavLink } from "react-router-dom";

export default function Sidebar({ isCollapsed }) {
  
  return (
    
    <nav id="sidebar" className={`sidebar js-sidebar ${isCollapsed ? 'collapsed' : ''}`}>
      <div className="sidebar-content js-simplebar">
        <Link className="sidebar-brand" to="/Dashboard">
          <span className="align-middle">KlonIT</span>
        </Link>

        <ul className="sidebar-nav">

          <li className="sidebar-header d-none-none">Main</li>

          <li className="sidebar-item d-none-none">
            <NavLink className="sidebar-link" to="/RetrainChatbot" activeClassName="active">
              <i className="align-middle" data-feather="sliders"></i>
              <span className="align-middle">Dashboard</span>
            </NavLink>
          </li>


          <li className="sidebar-header d-none-none">Configuration</li>

          <li className="sidebar-item d-none-none">
            <NavLink className="sidebar-link" to="/configuration" activeClassName="active">
              <i className="align-middle" data-feather="sliders"></i>
              <span className="align-middle">Website Configuration</span>
            </NavLink>
          </li>

          <li className="sidebar-item d-none-none">
            <NavLink className="sidebar-link" to="/avatar" activeClassName="active">
              <i className="align-middle" data-feather="avatar"></i>
              <span className="align-middle">Avatar</span>
            </NavLink>
          </li>

          <li className="sidebar-item d-none-none">
            <NavLink className="sidebar-link" to="/audio" activeClassName="active">
              <i className="align-middle" data-feather="audio"></i>
              <span className="align-middle">Audio & Language</span>
            </NavLink>
          </li>

          <li className="sidebar-item d-none-none">
            <NavLink className="sidebar-link" to="/thumbnail-color" activeClassName="active">
              <i
                className="align-middle"
                data-feather="Thumbnail and Color"
              ></i>
              <span className="align-middle">Thumbnail and Color</span>
            </NavLink>
          </li>

          <li className="sidebar-item d-none-none">
            <NavLink className="sidebar-link" to="/ChatbotSettings" activeClassName="active">
              <i
                className="align-middle"
                data-feather="LLM Settings"
              ></i>
              <span className="align-middle">Chatbot Settings</span>
            </NavLink>
          </li>

          <li className="sidebar-header">Data Sources</li>          

          <li className="sidebar-item">
            <NavLink className="sidebar-link" to="add-documents" activeClassName="active">
              <i className="align-middle" data-feather="Add Documents"></i>
              <span className="align-middle">Documents</span>
            </NavLink>
          </li>

          <li className="sidebar-item">
            <NavLink className="sidebar-link" to="add-image" activeClassName="active">
              <i className="align-middle" data-feather="Add Image"></i>
              <span className="align-middle">Image</span>
            </NavLink>
          </li>

          

          <li className="sidebar-item">
            <NavLink className="sidebar-link" to="add-website" activeClassName="active">
              <i className="align-middle" data-feather="Add Website Links"></i>
              <span className="align-middle">Website</span>
            </NavLink>
          </li>

            

          <li className="sidebar-header">Sessions</li>

          <li className="sidebar-item">
            <NavLink className="sidebar-link" to="/chat-history" activeClassName="active" >
              <i className="align-middle" data-feather="History"></i>
              <span className="align-middle">Chat History</span>
            </NavLink>
          </li>

          <li className="sidebar-header">Response Improvements</li>   

          <li className="sidebar-item">
            <NavLink className="sidebar-link" to="user-feedback" activeClassName="active">
              <i className="align-middle" data-feather="Answers"></i>
              <span className="align-middle">Feedbacks</span>
            </NavLink>
          </li>       

          <li className="sidebar-item">
            <NavLink className="sidebar-link" to="/question-improvements" activeClassName="active">
              <i className="align-middle" data-feather="Questions"></i>
              <span className="align-middle">Question Quality</span>
            </NavLink>
          </li>

          <li className="sidebar-item">
            <NavLink className="sidebar-link" to="answers-improvements" activeClassName="active">
              <i className="align-middle" data-feather="Answers"></i>
              <span className="align-middle">Ground Truth</span>
            </NavLink>
          </li>

          <li className="sidebar-item">
            <NavLink className="sidebar-link" to="Pronoun" activeClassName="active">
              <i className="align-middle" data-feather="Answers"></i>
              <span className="align-middle">Pronouns</span>
            </NavLink>
          </li>          

        </ul>
      </div>
    </nav>

  );
}

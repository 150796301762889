import React, { useState, useEffect } from "react";
import axios from "axios";
import backendURL from "../../config";
import ListSitemap from './ListSitemap';

export default function AddSitemap() {

  useEffect(() => {
    document.title = 'Add Sitemap | KlonIT Web Application';
  }, []);

  const [inputValue, setInputValue] = useState("");
  const [sitemaps, setSitemaps] = useState([]); // Changed Sitemaps to sitemaps for consistency
  const urlRegex = /^(https?:\/\/)?([\w.]+)\.([a-z]{2,})(\/[\w .-]*)*\/?$/i;
  const [error, setError] = useState(null);


  const handleAddWebBtnClick = async () => {
    if (urlRegex.test(inputValue)) {
      try {
        const token = localStorage.getItem('access_token');
        const headers = {
          Authorization: `Bearer ${token}`
        };

        // Send request to add sitemap
        const response = await axios.post(`${backendURL}/add-sitemap`, { sitemap: inputValue }, { headers });

        // Update sitemaps state with the new sitemap
        setSitemaps([...sitemaps, inputValue]); // Changed Sitemaps to sitemaps for consistency

        setInputValue(""); // Clear the input after adding
        setError("Sitemaps has been added successfully.");
      } catch (error) {
        console.error("Error adding Sitemaps:", error);
        setError("Error adding Sitemaps:", error);
      }
    } else {
      alert("Please enter a valid Sitemaps.");
    }
  };


  return (
    <div>
      <main className="content" style={{ minHeight: '100%' }}>
        <div className="container-fluid p-0">
          <div className="mb-3">
            <h1 className="h3 d-inline align-middle"> <strong>Add Sitemap</strong> Links</h1>
          </div>
          <div className="row">
            <div className="col-12 col-lg-12">
              <div className="card">
                <div className="card-body">
                  <h5 className="card-title mb-2">
                    (Link should be started with https://
                    or www and ends with .xml)
                  </h5>
                  <div className="row">
                    <div className="col-8 col-lg-8">
                      <input
                        type="text"
                        className="form-control"
                        placeholder="Input"
                        value={inputValue}
                        onChange={(e) => setInputValue(e.target.value)}
                      />
                    </div>
                    <div className="col-4 col-lg-4">
                      <button
                        className="btn btn-primary"
                        onClick={handleAddWebBtnClick}
                      >
                        Add Sitemaps
                      </button>
                    </div>

                  </div>

                  <div className="row">
                    <div className="col-12 col-lg-12">
                      
                      <div id="getError" className={`alert alert-warning ${error ? "" : "d-none"}`}>
                        <br />
                        <br />
                        {error}
                      </div>

                    </div>
                  </div>

                </div>
              </div>
            </div>
          </div>
        </div>
        {/* Pass a key to ListSitemap to trigger re-render when sitemaps state changes */}
        {/* <ListSitemap key={sitemaps.length} /> */}

      </main >
    </div >
  );
}

import React, { useState, useEffect } from "react";
import axios from "axios";
import DataTable from "react-data-table-component";
import backendURL from "../config";

// Define the Expandable Component
const ExpandedRow = ({ data }) => {
  return (
    <div style={{ padding: '10px 20px' }}>
      <h5>Details for {data.questions}</h5>
      <p><strong>Answers:</strong> {data.answers}</p>
      <p><strong>User Issue:</strong> {data.user_issue}</p>
      <p><strong>User Feedback:</strong> {data.user_feedback}</p>
      <p><strong>Create Date:</strong> {data.created_date}</p>
    </div>
  );
};

export default function ListUserFeedback() {
  const [documents, setDocuments] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [searchTerm, setSearchTerm] = useState("");

  useEffect(() => {
    document.title = 'User Feedback Lists | KlonIT Web Application';
    fetchDocuments();
  }, []);

  const fetchDocuments = async () => {
    try {
      const token = localStorage.getItem('access_token');
      const headers = { Authorization: `Bearer ${token}` };

      const response = await axios.get(`${backendURL}/get-user-feedback`, { headers });
      console.log("Response Data: ", response.data);

      if (response.data && response.data.feedbacks) {
        setDocuments(response.data.feedbacks);
      } else {
        setDocuments([]);
      }

      setLoading(false);
    } catch (error) {
      if (error.response) {
        console.error("Error Response Data: ", error.response.data);
        if (error.response.status === 401) {
          setError("Authentication error: " + error.response.data.error);
        } else {
          setError("Error fetching user feedback: " + error.response.data.error);
        }
      } else {
        console.error("Error: ", error.message);
        setError("Error fetching user feedback");
      }
      setLoading(false);
    }
  };

  // Columns definition for DataTable
  const columns = [
    {
      name: "Questions",
      selector: row => row.questions,
      sortable: true,
      maxWidth: '315px',
    },
    {
      name: "Answers",
      selector: row => row.answers,
      sortable: true,
      maxWidth: '315px',
    },
    {
      name: "User Issue",
      selector: row => row.user_issue,
      sortable: true,
      maxWidth: '315px',
    },
    {
      name: "User Feedback",
      selector: row => row.user_feedback,
      sortable: true,
      maxWidth: '315px',
    },
    {
      name: "Create Date",
      selector: row => row.created_date,
      sortable: true,
      maxWidth: '315px',
    },
  ];

  // Filtering documents based on search term
  const filteredDocuments = documents.filter(document =>
    document.questions.toLowerCase().includes(searchTerm.toLowerCase()) ||
    document.answers.toLowerCase().includes(searchTerm.toLowerCase()) ||
    document.user_issue.toLowerCase().includes(searchTerm.toLowerCase()) ||
    document.user_feedback.toLowerCase().includes(searchTerm.toLowerCase()) ||
    document.created_date.toLowerCase().includes(searchTerm.toLowerCase())
  );

  return (
    <div>
      <main className="content">
        <div className="mb-3"><h1 className="h3 d-inline align-middle"> <strong>User</strong> Feedbacks</h1></div>
        <div className="row">
          <div className="col-12 col-lg-12 col-xxl-12 d-flex">
            <div className="card flex-fill">
              <div className="card-header">
                <div className="row">
                  <div className="col-lg-4">
                  </div>
                  <div className="col-lg-4">
                  </div>
                  <div className="col-lg-4">
                    <input
                      type="text"
                      placeholder="Search..."
                      value={searchTerm}
                      onChange={(e) => setSearchTerm(e.target.value)}
                      className="form-control"
                    />
                  </div>
                </div>
              </div>
              <div className="card-body">
                {loading ? (
                  <p>Loading...</p>
                ) : error ? (
                  <div className="alert alert-warning">{error}</div>
                ) : (
                  <DataTable
                    columns={columns}
                    data={filteredDocuments}
                    pagination
                    expandableRows
                    expandOnRowClicked
                    highlightOnHover
                    progressPending={loading}
                    noDataComponent="No data available"
                    expandableRowsComponent={({ data }) => <ExpandedRow data={data} />}
                  />
                )}
              </div>
            </div>
          </div>
        </div>
      </main>
    </div>
  );
}

import React, { useState, useEffect } from "react";
import axios from "axios";
import DataTable from 'react-data-table-component';
import Modal from "react-modal";
import backendURL from "../../config";
import RetrainButton from "../Chatbot/RetrainButton"
import EditImage from "./EditImage";

export default function ListImages({refresh}) {
  useEffect(() => {
    document.title = 'Uploaded Files | KlonIT Web Application';
    fetchImages();
  }, [refresh]);

  const [images, setImages] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [currentDocument, setCurrentDocument] = useState(null);
  const [triggerFetch, setTriggerFetch] = useState(false);

  // Function to fetch images from the API
  const fetchImages = async () => {
    try {
      const token = localStorage.getItem('access_token'); 
      const headers = {
        Authorization: `Bearer ${token}`
      };

      const response = await axios.get(`${backendURL}/get-images`, { headers });
      setImages(response.data);
      setLoading(false);
    } catch (error) {
      if (error.response && error.response.status === 401) {
        console.error("Authentication error:", error.response.data.error);
        setError("Authentication error:", error.response.data.error);
      } else {
        console.error("Error fetching images:", error);
        setError("Error fetching images:", error);
      }
    }
  };

  const handleDelete = async (imageId) => {
    try {
      const token = localStorage.getItem('access_token');
      const headers = {
        Authorization: `Bearer ${token}`
      };

      await axios.delete(`${backendURL}/delete-image/${imageId}`, { headers });
      setImages(images.filter(doc => doc.id !== imageId));
      setError("Image has been deleted successfully.");
    } catch (error) {
      console.error("Error deleting image:", error);
      setError("Error deleting image:", error);
    }
  };

  // Define columns for the DataTable
  const columns = [
    {
      name: 'File Name',
      selector: row => row.filename,
      sortable: true,
    },
    {
      name: 'Page Content',
      selector: row => row.image_content,
      sortable: true,
      maxWidth: '315px',
    },
    {
      name: 'Is Scrape?',
      selector: row => row.is_scrape ? "Yes" : "No",
      sortable: true,
      hide: 'md' // Hide column on smaller screens
    },
    {
      name: 'Is Trained?',
      selector: row => row.is_trained ? "Yes" : "No",
      sortable: true,
      omit: false,
    },
    {
      name: 'Upload Date',
      selector: row => row.upload_date,
      sortable: true,
      hide: 'md' // Hide column on smaller screens
    },
    {
      name: 'Action',
      cell: row => (
        <>
          <button className="deleteIcon" onClick={() => handleDelete(row.id)}>
            <i className="fa fa-trash-o fa-2x"></i>
          </button>
          <button className="EditIcon" onClick={() => handleEdit(row)}>
            <i className="fa fa-pencil fa-2x"></i>
          </button>
        </>
      )
    }
  ];

  const ExpandedComponent = ({ data }) => (
    <div>
      <p><strong>File Name:</strong> {data.filename}</p>
      <p><strong>Page Content:</strong> {data.image_content}</p>
    </div>
  );

  const handleEdit = (document) => {
    setCurrentDocument(document);
    setIsModalOpen(true);
  };

  const closeModal = () => {
    setIsModalOpen(false);
    setCurrentDocument(null);
    setTriggerFetch(!triggerFetch);
  };

  const EditModalStyle = {
    content: {
      width: "50%",
      height: "50%",
      margin: "auto",
      padding: "20px",
      borderRadius: "10px",
      boxShadow: "0 4px 6px rgba(0, 0, 0, 0.1)",
    },
    overlay: {
      backgroundColor: "rgba(0, 0, 0, 0.5)",
    },
  };
  

  return (
    <div>

      <RetrainButton/>

      <div className="row">
        <div className="col-12 col-lg-12 col-xxl-12 d-flex">
          <div className="card flex-fill">
            <div className="card-header">
              {/* <h5 className="card-title mb-0">Uploaded Files</h5> */}
            </div>
            <div className="card-body">
              {loading ? (
                <p>Loading...</p>
              ) : images.length === 0 ? (
                <p>No data available</p>
              ) : (
                <DataTable
                  columns={columns}
                  data={images}
                  pagination
                  responsive                
                  highlightOnHover
                  pointerOnHover
                  expandableRows
                  expandableRowsComponent={({ data }) => <ExpandedComponent data={data} />}
                  expandOnRowClicked
                />
              )}
              <br/>
              <div id="getError" className={`alert alert-warning ${error ? "" : "d-none"}`}>{error}</div>
              <br/>
            </div>
          </div>
        </div>
      </div>

      <Modal isOpen={isModalOpen} onRequestClose={closeModal} contentLabel="Edit Images" style={EditModalStyle}>
        <EditImage document={currentDocument} onClose={closeModal} onUpdate={fetchImages} />
      </Modal>

    </div>
  );
}

import React, { useState, useEffect } from "react";
import { ChatScriptsURL } from "../../config";
import backendURL from "../../config";
import {APPLICATION_URL} from "../../config";
import axios from "axios";

export default function ChatbotScripts() {
	const [error, setError] = useState(null);
	const [scriptFilename, setScriptFilename] = useState("");
	const [scriptData, setScriptData] = useState(null);

	useEffect(() => {
		document.title = 'Retrain Chatbot | KlonIT Web Application';
		fetchData();
	}, []);

	// Function to fetch data from the API
	const fetchData = async () => {
		try {
			const token = localStorage.getItem('access_token');
			const headers = { Authorization: `Bearer ${token}` };

			// Make the API request
			const response = await axios.post(`${backendURL}/get-javascript`, {}, { headers });
			console.log("Get Data: ", response.data);

			// Save the fetched data to state
			setScriptData(response.data);

		} catch (error) {
			console.error("Full error details:", error);  // Log the full error to console for debugging

			if (error.response) {
				setError(`Error fetching website: ${error.response.data.error || error.response.statusText}`);
			} else if (error.request) {
				setError("No response received from the server. Please try again.");
			} else {
				setError(`Error setting up request: ${error.message}`);
			}
		}
	};

	// Function to update the script with dynamic data
	useEffect(() => {
		if (scriptData) {
			const dynamicScript = `
<!-- Add in the <head> section -->
<script src="${ChatScriptsURL}/assets/js/master.js"></script>
<!-- Add before closing </body> tag -->
<script>
	CloneMeChatbot.init({
		baseUrl: '${APPLICATION_URL}/assets',
		botName: '${scriptData.website_path}',
		website_path: '${scriptData.website_path}',
		website_url: '${scriptData.website_url}',
		gif_img_path: '${APPLICATION_URL}/images/gif/avatar_img (1).gif',
		png_img_path: '${APPLICATION_URL}/images/avatars/avatar_img (1).png',
		app_voice_type: "${scriptData.app_voice_type}",						
	});
</script>
			`;

			// Update the script with the dynamic data
			setScriptFilename(dynamicScript);
		}
	}, [scriptData]); // Run this effect when scriptData changes

	// Function to handle copying the script to the clipboard
	const handleCopyClick = () => {
		navigator.clipboard.writeText(scriptFilename)
			.then(() => {
				alert("Script copied to clipboard!");
			})
			.catch((error) => {
				console.error("Copy failed", error);
			});
	};

	return (
		<div>
			<main className="content">
				<div className="container-fluid p-0">					

					{/* New Script Section */}
					<div className="row">
						<div className="col-md-12">
							<h4>Script</h4>
							<div className="card">
								<div className="card-body">
									<div className="form-group">
										<textarea 
											className="form-control" 
											value={scriptFilename} 
											readOnly 
											rows="15"
										/>
										<button 
											className="btn btn-secondary mt-2" 
											onClick={handleCopyClick}
										>
											<i className="fa fa-copy"></i> Copy
										</button>
									</div>
								</div>
							</div>
						</div>
					</div>

					<div id="getError" className={`alert alert-warning ${error ? "" : "d-none"}`}>{error}</div>

				</div>
			</main>
		</div>
	);
}
